import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#f1b80d',
      contrastText: grey[50],
    },
    secondary: {
      main: '#4E4F51',
      light: '#717274',
      contrastText: '#ffffff',
    },
  },
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#4E4F51',
        color: '#ffffff',
      },
    },
  },
});

export default responsiveFontSizes(theme);
