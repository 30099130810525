import { Client as TwilioClient } from '@twilio/conversations';
import { Conversation as TwilioConversation } from '@twilio/conversations/lib/conversation';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { Message } from '@twilio/conversations/lib/message';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { ConversationClient } from './conversation.client';
import { log } from './logger';

export interface ChatClientOptions {
  tokenGetter: () => Promise<string | undefined>;
  startChat: (userId: string) => Promise<string | undefined>;
  debug?: boolean;
  pageSize?: number;
  listenToAllConversations?: boolean;
}

export interface ConversationRecord {
  conversation: ConversationClient;
  messageSub: Subscription;
}

export class ChatClient {
  /**
   * Twilio client
   */
  public client!: TwilioClient;

  /**
   * Twilio token
   * @private
   */
  private readonly tokenGetter: () => Promise<string | undefined>;

  private readonly _startChat: (userId: string) => Promise<string | undefined>;

  /**
   * Current conversations
   * @private
   */
  private conversationRecords: ConversationRecord[] = [];

  private conversationRecordsSubscription!: Subscription;

  private $nextConv = new Subject<ConversationClient>();

  private $currentConv = new BehaviorSubject<ConversationClient | null>(null);

  private $nextMessage = new Subject<Message>();

  private inited = false;

  private log: typeof log = () => undefined;

  get conversations(): Observable<ConversationClient> {
    return this.$nextConv.asObservable();
  }

  get currentConversation(): Observable<ConversationClient | null> {
    return this.$currentConv.asObservable();
  }

  get newMessage(): Observable<Message> {
    return this.$nextMessage.asObservable();
  }

  constructor(
    private readonly options: ChatClientOptions,
  ) {
    this.tokenGetter = options.tokenGetter;
    this._startChat = options.startChat;

    if (options.debug) {
      this.log = log;
    }
  }

  static async create(options: ChatClientOptions): Promise<ChatClient> {
    const client = new ChatClient(options);
    await client.init();

    return client;
  }

  /**
   * When the current user joins a conversation
   * This is called also for existing conversations on client init
   * @param conversation
   */
  private onConversationJoined = async (conversation: TwilioConversation) => {
    this.log('join conv', conversation.uniqueName);
    this.$nextConv.next(await ConversationClient.create({
      twilioConversation: conversation,
      currentUser: this.client.user,
      debug: this.options.debug,
      pageSize: this.options.pageSize,
    }));
  };

  private updateToken = async () => {
    this.log('Update token');
    const newToken = await this.tokenGetter();
    if (!newToken) {
      throw new Error('Missing new token');
    }
    await this.client.updateToken(newToken);
  }

  /**
   * Init Twilio client
   */
  async init(): Promise<void> {
    if (!this.tokenGetter) {
      throw new Error('Missing token getter');
    }

    const token = await this.tokenGetter();
    if (!token) {
      throw new Error('Missing token');
    }

    if (this.inited) {
      return Promise.resolve();
    }
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      this.client = await TwilioClient.create(token);

      this.log('TwilioClient', this.client);

      /**
       * When a new conversation is joined, listen for new messages on that conversation
       */
      this.conversationRecordsSubscription = this.conversations.subscribe(conv => {
        this.log('New conv subscription', conv);
        const sub = conv.listenMessages()
          .pipe(
            // Filter new messages if it is current conversation (here we handle only background conversation new messages)
            withLatestFrom(this.currentConversation),
            filter(([mess, curr]: [Message, ConversationClient | null]) => {
              if (!curr) {
                return true;
              }
              return mess.conversation.sid !== curr.sid;
            }),
            map(([mess]: [Message, ConversationClient | null]) => mess),
          )
          .subscribe((message: Message) => {
            this.log('Conv', conv.uniqueName, 'new message');
            this.$nextMessage.next(message);
          });

        this.conversationRecords.push({
          conversation: conv,
          messageSub: sub,
        });
      });

      if (this.options.listenToAllConversations) {
        this.client.on('conversationJoined', this.onConversationJoined);
      }

      this.client.on('tokenAboutToExpire', this.updateToken);
      this.client.on('tokenExpired', this.updateToken);

      this.client.on('error', err => this.log('Error', err));

      this.client.on('connectionStateChanged', (state: TwilioClient.ConnectionState) => {
        this.log('connection changed:', state);
        // Twilio is ready
        if (['connected'].includes(state)) {
          this.inited = true;
          resolve();
        }
      });
    });
  }

  /**
   * Should be called when disposing the chat
   */
  cleanUp(): Promise<void> {
    this.log('Cleanup');
    // Clear all Twilio client listeners
    this.client.removeAllListeners();
    // Unsubscribe from new messages on each client
    // Clear conversation
    this.conversationRecords.forEach(({ conversation, messageSub }) => {
      messageSub.unsubscribe();
      conversation.cleanUp();
    });
    // Unsubscribe to new conversation
    this.conversationRecordsSubscription.unsubscribe();
    this.conversationRecords = [];
    // Shutdown Twilio client
    return this.client.shutdown();
  }

  /**
   * Create a new chat or get an existing one if exists
   * @param destUserId
   */
  async startChat(destUserId: string): Promise<ConversationClient> {
    try {
      const chatName = await this._startChat(destUserId);
      this.log('Chat uniqueName', chatName);

      const conv = await this.client.getConversationByUniqueName(chatName!);
      this.log('Conv', conv);

      const client = await ConversationClient.create({
        twilioConversation: conv,
        currentUser: this.client.user,
        debug: this.options.debug,
        pageSize: this.options.pageSize,
      });

      this.log('Conv client', client);

      this.$currentConv.next(client);

      return client;
    } catch (e) {
      this.log('Cannot create chat', e);
      throw e;
    }
  }

  closeSelectedChat() {
    this.log('Close chat');
    if (this.$currentConv.value) {
      this.$currentConv.value.cleanUp();
    }
    this.$currentConv.next(null);
  }
}
