import { ReactNode } from 'react';
import { DialogProps } from '@material-ui/core';
import { Actions } from './actions';

export interface DialogType extends Omit<DialogProps, 'children' | 'title'> {
  body?: any;
  title?: ReactNode;
  dialogTitleProps?: import('../components/shared-dialog').DialogTitleProps;
}

export interface DialogState {
  dialog: DialogType;
}

export const dialogInitialState: DialogState = {
  dialog: {
    open: false,
  },
};

export type DialogActionType = (state: DialogState, newState: DialogType) => DialogState
export type DialogActionsType = {
  [Actions.DIALOG_OPEN]: DialogActionType;
  [Actions.DIALOG_CLOSE]: DialogActionType;
}

export interface DialogAction extends Omit<DialogType, 'open'> {
  type: Actions.DIALOG_OPEN | Actions.DIALOG_CLOSE;
}

export const dialogActions: DialogActionsType = {
  [Actions.DIALOG_OPEN]: (state, newState) => ({
    ...state,
    dialog: {
      ...state.dialog,
      ...newState,
      open: true,
    },
  }),
  [Actions.DIALOG_CLOSE]: (state, newState) => ({
    ...state,
    dialog: {
      ...newState,
      open: false,
    },
  }),
};
