import {
  BehaviorSubject, Subject, Subscription, Observable, defer,
} from 'rxjs';
import { Message } from '@twilio/conversations/lib/message';
import { Paginator } from '@twilio/conversations/lib/interfaces/paginator';
import { Conversation as TwilioConversation } from '@twilio/conversations/lib/conversation';
import { User } from '@twilio/conversations';
import { Participant } from '@twilio/conversations/lib/participant';
import { log } from './logger';
import { handleRetry } from './utils';

export interface ConversationClientOptions {
  twilioConversation: TwilioConversation;
  currentUser: User;
  pageSize?: number;
  debug?: boolean;
}

export class ConversationClient {
  private newMessagesSub?: Subscription;

  public currentParticipant?: Participant;

  private $messages = new BehaviorSubject<Message[]>([]);

  private $newMessage = new Subject<Message>();

  private $loading = new BehaviorSubject(false);

  private $typing = new BehaviorSubject(false);

  private prevPageFn: (() => Promise<Paginator<Message>>) | null = null;

  get hasPrevPage(): boolean {
    return !!this.prevPageFn;
  }

  get messages(): Observable<Message[]> {
    return this.$messages.asObservable();
  }

  get newMessage(): Observable<Message> {
    return this.$newMessage.asObservable();
  }

  get typing(): Observable<boolean> {
    return this.$typing.asObservable();
  }

  get loading(): Observable<boolean> {
    return this.$loading.asObservable();
  }

  get sid(): string {
    return this.twilioConversation.sid;
  }

  get uniqueName(): string {
    return this.twilioConversation.uniqueName;
  }

  public readonly twilioConversation: TwilioConversation;

  private readonly currentUser: User;

  private readonly pageSize: number;

  private log: typeof log = () => undefined;

  constructor(
    private readonly options: ConversationClientOptions,
  ) {
    this.twilioConversation = options.twilioConversation;
    this.currentUser = options.currentUser;
    this.pageSize = options.pageSize || 25;
    if (options.debug) {
      this.log = log;
    }
  }

  static async create(options: ConversationClientOptions): Promise<ConversationClient> {
    const conversation = new ConversationClient(options);
    await conversation.init();

    return conversation;
  }

  async init(): Promise<void> {
    this.log('Init single conv', this.twilioConversation.uniqueName);
    this.newMessagesSub = this.listenMessages().subscribe();

    const participants = await this.twilioConversation.getParticipants();

    this.currentParticipant = participants.find(p => p.identity === this.currentUser.identity);

    this.log('Participants', participants);
    this.log('Current participant', this.currentParticipant);
  }

  /**
   * Send a new message to the conversation
   * @param message
   * @param messageAttributes
   */
  sendMessage(message: string | FormData | TwilioConversation.SendMediaOptions | null, messageAttributes?: unknown): Promise<number> {
    this.log('Sending new message', message);
    return this.twilioConversation.sendMessage(message, messageAttributes);
  }

  /**
   * Set all conversation messages read for the current user
   */
  async readMessages(): Promise<void> {
    this.log('Set all messages as read');
    await this.twilioConversation.setAllMessagesRead();
  }

  /**
   * When a new message is written to the conversation
   * @param message
   */
  private onMessage = (message: Message) => {
    this.log('New message received', message);

    this.$newMessage.next(message);
    this.$messages.next([...this.$messages.value, message]);
  }

  /**
   * Listen to a new message added
   */
  listenMessages(): Observable<Message> {
    this.log('Listening messages');
    this.twilioConversation.on('messageAdded', this.onMessage);
    return this.newMessage;
  }

  /**
   * Get last messages based on pageSize
   */
  async getMessages(): Promise<Observable<Message[]>> {
    this.prevPageFn = () => this.twilioConversation.getMessages(this.pageSize);

    await this.prevPage(true);

    return this.messages;
  }

  /**
   * Handle other user typing events
   */
  listenTyping(): Observable<boolean> {
    this.log('Listen to typing');
    this.twilioConversation.on('typingStarted', (member: Participant) => {
      this.log('Typing started', member);
      this.$typing.next(true);
    });
    this.twilioConversation.on('typingEnded', (member: Participant) => {
      this.log('Typing ended', member);
      this.$typing.next(false);
    });

    return this.typing;
  }

  setTyping(): Promise<void> {
    this.log('Setting typing');
    return this.twilioConversation.typing();
  }

  /**
   * Load messages history
   * @param reset
   */
  async prevPage(reset?: boolean): Promise<void> {
    this.log('Getting prev page?', !this.$loading.value);

    if (!this.prevPageFn) {
      return;
    }

    if (this.$loading.value) {
      return;
    }

    this.$loading.next(true);

    const retryPrevPageFn = defer(() => this.prevPageFn!())
      .pipe(handleRetry({
        log: this.log,
      }))
      .toPromise();

    try {
      const paginator = await retryPrevPageFn;
      this.prevPageFn = paginator.hasPrevPage ? paginator.prevPage : null;

      this.$messages.next([
        ...paginator.items,
        ...(!reset ? this.$messages.value : []),
      ]);
    } finally {
      this.$loading.next(false);
    }
  }

  cleanUp(): void {
    this.log('Cleanup Conv');
    this.twilioConversation.removeAllListeners();
    this.newMessagesSub?.unsubscribe();
  }
}
