import { useCallback, useMemo } from 'react';
import { OptionsObject, useSnackbar, VariantType } from 'notistack';

export type ToastData = OptionsObject & { severity?: VariantType };

export const useToast = (defaults?: Partial<ToastData>) => {
  const { enqueueSnackbar } = useSnackbar();

  const open = useCallback((message: string, data?: ToastData & { severity?: VariantType }) => {
    enqueueSnackbar(message, {
      ...defaults,
      ...data,
      variant: data?.variant || data?.severity || defaults?.variant || defaults?.severity,
    });
  }, [defaults, enqueueSnackbar]);

  const error = useCallback((message: string, data?: ToastData) => open(message, {
    ...data,
    variant: 'error',
  }), [open]);

  const info = useCallback((message: string, data?: ToastData) => open(message, {
    ...data,
    variant: 'info',
  }), [open]);

  const success = useCallback((message: string, data?: ToastData) => open(message, {
    ...data,
    variant: 'success',
  }), [open]);

  const warning = useCallback((message: string, data?: ToastData) => open(message, {
    ...data,
    variant: 'warning',
  }), [open]);

  return useMemo(() => ({
    open,
    error,
    info,
    success,
    warning,
  }), [
    open,
    error,
    info,
    success,
    warning,
  ]);
};
