import React, { useMemo } from 'react';
import Grid, { GridProps } from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Backdrop from '@material-ui/core/Backdrop';

export interface LoadingProps extends GridProps {
  onlyIcon?: boolean;
  backdrop?: boolean;
  text?: string;
}

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  fullHeight: {
    height: '100%',
  },
}));

export const Loading: React.FC<LoadingProps> = ({ text = 'Loading...', backdrop = false, onlyIcon = false, style, ...props }) => {
  const classes = useStyles();

  const _style = { height: '100%', ...style };

  const contents = useMemo(() => (
    <Grid container direction="column" justify="center" alignItems="center" className={classes.fullHeight} style={_style} {...props}>
      <Grid item>
        <CircularProgress size={24} color="secondary"/>
      </Grid>
      {!onlyIcon && (
        <Grid item>
          <Typography variant="overline" align="center" component="p">{text}</Typography>
        </Grid>
      )}
    </Grid>
  ), [text, onlyIcon]);

  if (backdrop) {
    return (
      <Backdrop className={classes.backdrop} open>
        {contents}
      </Backdrop>
    );
  }
  return contents;
};
