import React, {
  createContext, useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { Loading } from '@hu-care/react-layout';
import { Observable, Subscription } from 'rxjs';
import { Message } from '@twilio/conversations/lib/message';
import { ChatClient } from '../chat.client';
import { ConversationClient } from '../conversation.client';

export interface ChatContext {
  ready: boolean;
  selectedConv: ConversationClient | null;
  startChat: (destUserId: string) => Promise<unknown>;
  closeChat: () => void;
  startChatLoading: boolean;
  newMessage?: Observable<Message>;
  chatClient: ChatClient | null;
}

export interface ChatProviderProps {
  getToken: () => Promise<string | undefined>;
  startChat: (userId: string) => Promise<string | undefined>;
  listenToAllConversations?: boolean;
  debug?: boolean;
  waitForReady?: boolean;
}

const ChatCtx = createContext<ChatContext>({} as any);

export const useChat = (): ChatContext => useContext(ChatCtx);

export const ChatProvider: React.FC<ChatProviderProps> = ({
  children,
  startChat,
  getToken,
  listenToAllConversations,
  debug,
  waitForReady = true,
}) => {
  const [ready, setReady] = useState(false);
  const [startChatLoading, setStartChatLoading] = useState(false);

  const [selectedConv, setCurrentConv] = useState<ConversationClient | null>(null);

  const [chatClient, setChatClient] = useState<ChatClient | null>(null);

  const chatRef = useRef<ChatClient | null>();

  useEffect(() => {
    let convSub: Subscription;
    let currentConvSub: Subscription;

    ChatClient.create({
      tokenGetter: getToken,
      startChat,
      listenToAllConversations,
      debug,
    }).then(client => {
      chatRef.current = client;
      setChatClient(client);
      currentConvSub = chatRef.current.currentConversation.subscribe(conv => setCurrentConv(conv));

      setReady(true);
    });

    return () => {
      if (convSub) {
        convSub.unsubscribe();
      }
      if (currentConvSub) {
        currentConvSub.unsubscribe();
      }
      if (chatRef.current) {
        chatRef.current.cleanUp()
          .finally(() => {
            chatRef.current = null;
          });
        setReady(false);
      }
    };
  }, [getToken, setCurrentConv, startChat, debug, setChatClient]);

  const securedStartChat = useCallback((destUserId: string) => {
    if (!chatRef.current) {
      return Promise.reject(new Error('Chat not ready'));
    }

    setStartChatLoading(true);

    return chatRef.current.startChat(destUserId)
      .finally(() => {
        setStartChatLoading(false);
      });
  }, []);

  const closeChat = useCallback(() => {
    if (!chatRef.current) {
      return;
    }
    chatRef.current.closeSelectedChat();
  }, []);

  const value = useMemo<ChatContext>(() => ({
    ready,
    selectedConv,
    startChat: securedStartChat,
    startChatLoading,
    newMessage: chatRef.current?.newMessage,
    closeChat,
    chatClient,
  }), [ready, selectedConv, securedStartChat, startChatLoading, chatClient, closeChat]);

  if (!ready && waitForReady) {
    return <Loading/>;
  }

  return (
    <ChatCtx.Provider value={value}>
      {children}
    </ChatCtx.Provider>
  );
};
