import { parseCf } from '../parse-cf';

const today = new Date();

export const getAge = (dobString: string) => Math.floor((today as any - new Date(dobString).getTime()) / 3.15576e+10);

export const getAgeFromCf = (cf?: string) => {
  if (!cf) return null;
  const parsed = parseCf(cf);
  if (parsed.birthday) {
    return getAge(parsed.birthday);
  }
  return null;
};
