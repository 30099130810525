import { parseISO, format as fnsFormat } from 'date-fns';
import { it } from 'date-fns/esm/locale';

export const format = (d: Date, f: string) => fnsFormat(d, f, {
  locale: it,
});

export const formatDate = (dateString: string) => format(parseISO(dateString), 'dd/MM/yyyy');

export const formatDateTime = (dateString: string) => format(parseISO(dateString), 'dd/MM/yyyy HH:mm:ss');

export const formatToServer = (dateString: string | Date, withTrailingTime = true): any => {
  const value = typeof dateString === 'string' ? parseISO(dateString) : dateString!;
  return format(value, `yyyy-MM-dd${withTrailingTime ? ' HH:mm:ss' : ''}`);
};
