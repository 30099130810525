import React, { memo, PropsWithChildren } from 'react';
import { Container, Toolbar, ContainerProps } from '@material-ui/core';
import clsx from 'clsx';
import { LayoutState, useLayout } from '../contexts';
import { EnvBar } from './env-bar';
import { useLayoutStyles } from '../styles';

export interface NavBarProps extends LayoutState {
  getTitle?: (props: any) => any;
}

export interface LayoutProps extends ContainerProps {
  navBar: (props: NavBarProps) => JSX.Element;
  rootClassName?: string;
  mainClassName?: string;
  showEnvBar?: boolean;
}

export const Layout = memo<PropsWithChildren<LayoutProps>>(({
  children,
  navBar,
  rootClassName,
  mainClassName,
  showEnvBar = true,
  ...containerProps
}) => {
  const classes = useLayoutStyles();
  const state = useLayout();

  const NavBar = navBar(state);

  return (
    <div className={clsx(classes.root, rootClassName)} style={{ backgroundColor: state.background }}>
      {NavBar}
      <main className={clsx(classes.content, mainClassName)}>
        <Toolbar/>
        { showEnvBar && <EnvBar/> }
        <Container maxWidth="xl" {...containerProps}>
          {children}
        </Container>
      </main>
    </div>
  );
});
