import {
  useCallback, useEffect, useMemo, useState, BaseSyntheticEvent,
} from 'react';
import { Subscription } from 'rxjs';
import { formatToServer } from '@hu-care/react-utils';
import { useForm, UseFormMethods } from 'react-hook-form';
import { Message } from '@twilio/conversations/lib/message';
import { ConversationClient } from '../conversation.client';
import { UiMessage } from '../chat.types';
import { useChat } from './chat.context';

export interface ConversationForm {
  message: string;
  file: File | null;
}

const toUiMessage = async (message: Message): Promise<UiMessage> => {
  const commonMessage = {
    id: message.sid,
    author: message.author,
    date: formatToServer(message.dateCreated, true),
  };
  if (message.type === 'media') {
    return {
      ...commonMessage,
      type: 'media',
      media: {
        urlFn: message.media.getContentTemporaryUrl.bind(message.media),
        size: message.media.size,
        mime: message.media.contentType,
        name: message.media.filename,
      },
    };
  }
  return {
    ...commonMessage,
    type: 'text',
    body: message.body,
  };
};

export interface UseConversation {
  sendMessage: (e?: BaseSyntheticEvent) => Promise<void>;
  form: UseFormMethods<ConversationForm>;
  loading: boolean;
  messages: UiMessage[];
  typing: boolean;
  lastMessageId: string | null;
  sending: boolean;
}

export const useConversation = (selectedConv?: ConversationClient | null): UseConversation => {
  const [loading, setLoading] = useState(false);
  const [typing, setTyping] = useState<boolean>(false);
  const [messages, setMessages] = useState<UiMessage[]>([]);
  const [sending, setSending] = useState(false);
  const [lastMessageId, setLastMessageId] = useState<string | null>(null);

  /**
   * New message form
   */
  const form = useForm<ConversationForm>({
    defaultValues: {
      message: '',
      file: null,
    },
  });

  /**
   * Add file field to form
   */
  useEffect(() => {
    form.register({ name: 'file' });
  }, [form]);

  /**
   * Main effect, when the passed conversation changes
   */
  useEffect(() => {
    let msgSub: Subscription;
    let typingSub: Subscription;
    let loadingSub: Subscription;

    (async () => {
      if (selectedConv) {
        // Subscribe to conversation loading
        loadingSub = selectedConv.loading
          .subscribe(bool => {
            setLoading(bool);
          });

        // Subscribe to incoming messages
        // Messages are always an array of all visible messages
        msgSub = (await selectedConv.getMessages())
          .subscribe(async newMessages => {
            const newUiMessages: UiMessage[] = await Promise.all(
              newMessages.map(async message => toUiMessage(message)),
            );
            setMessages(newUiMessages);
          });

        // Subscribe to other user typing
        typingSub = selectedConv.listenTyping()
          .subscribe(bool => setTyping(bool));
      }
    })();

    // Remove all subscriptions on effect cleanup
    return () => {
      if (msgSub) {
        msgSub.unsubscribe();
      }
      if (typingSub) {
        typingSub.unsubscribe();
      }
      if (loadingSub) {
        loadingSub.unsubscribe();
      }
    };
  }, [selectedConv]);

  useEffect(() => {
    if (messages.length) {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage.id !== lastMessageId) {
        setLastMessageId(lastMessage.id);
      }
    }
  }, [messages, setLastMessageId, lastMessageId]);

  /**
   * Send a new message
   */
  const sendMessage = useCallback(({ message, file }: ConversationForm) => {
    if (!selectedConv || sending) return;
    const payloads = [];

    if (file) {
      const payload = new FormData();
      payload.append('file', file);
      payload.append('filename', file.name);
      payloads.push(payload);
    }
    if (message) {
      payloads.push(message);
    }

    if (!payloads.length) {
      return;
    }

    setSending(true);
    // eslint-disable-next-line arrow-body-style
    payloads.reduce((promiseChain, currentPayload) => {
      return promiseChain.then(() => selectedConv.sendMessage(currentPayload));
    }, Promise.resolve(0))
      .then(() => {
        form.reset({
          message: '',
          file: null,
        });
        setSending(false);
      });
  }, [form, selectedConv, sending, setSending]);

  return useMemo<UseConversation>(() => ({
    sendMessage: form.handleSubmit(sendMessage),
    form,
    loading,
    messages,
    typing,
    lastMessageId,
    sending,
  }), [sendMessage, form, loading, messages, typing, lastMessageId, sending]);
};
