import React from 'react';
import {
  DialogTitleProps as MuiDialogTitleProps,
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent,
  Typography,
  IconButton,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
// eslint-disable-next-line import/no-cycle
import { useStore } from '../context';

const useStyles = makeStyles(theme => ({
  closeButton: {
    marginLeft: 'auto',
    marginRight: -theme.spacing(1),
    marginTop: -theme.spacing(1),
    marginBottom: -theme.spacing(1),
    color: 'inherit',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  fullScreenTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    marginBottom: theme.spacing(2),
    boxShadow: theme.shadows[4],
  },
}));

export interface DialogTitleProps extends MuiDialogTitleProps {
  fullScreen?: boolean;
  onClose?: (...attr: any) => void;
  disableCloseButton?: boolean;
}

export const DialogTitle: React.FC<DialogTitleProps> = ({ children, onClose, fullScreen, disableCloseButton, ...other }) => {
  const classes = useStyles();
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      className={clsx(other.className, classes.title, {
        [classes.fullScreenTitle]: fullScreen,
      })}
    >
      {typeof children === 'string' ? (
        <Typography variant="h6">{children}</Typography>
      ) : children}
      {(onClose && !disableCloseButton) ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size={fullScreen ? 'small' : undefined}
        >
          <Close/>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

export const SharedDialog: React.FC = () => {
  const { state: { dialog: { body, title, dialogTitleProps, ...dialog } } } = useStore();

  return (
    <>
      <Dialog
        PaperProps={{ square: true }}
        aria-labelledby="shared-dialog-title"
        {...dialog}
      >
        {dialog.open && (
          <>
            <DialogTitle
              id="shared-dialog-title"
              onClose={dialog.onClose}
              fullScreen={dialog.fullScreen}
              {...dialogTitleProps}
            >
              {title}
            </DialogTitle>
            <DialogContent>
              {body}
            </DialogContent>
          </>
        )}
      </Dialog>
    </>
  );
};
