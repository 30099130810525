import { Observable } from 'rxjs';
import { delay, retryWhen, scan } from 'rxjs/operators';

export interface HandleRetryOptions {
  retryAttempts?: number;
  retryDelay?: number;
  log?: typeof import('./logger').log;
}

export function handleRetry(options: HandleRetryOptions = {}) {
  const { retryAttempts = 3, retryDelay = 500, log = () => undefined } = options;
  return <T>(source: Observable<T>): Observable<T> => source.pipe(
    retryWhen(e => e.pipe(
      scan((errorCount, error) => {
        log('Retrying', errorCount, error);
        if (errorCount + 1 >= retryAttempts) {
          throw error;
        }
        return errorCount + 1;
      }, 0),
      delay(retryDelay),
    )),
  );
}
