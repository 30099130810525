// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import CodiceFiscale from 'codice-fiscale-js';

export const parseCf = (cf: string): CodiceFiscaleObject => {
  try {
    return (new CodiceFiscale(cf.toUpperCase())).toJSON();
  } catch (e) {
    return {};
  }
};

export interface CodiceFiscaleObject {
  name?: string;
  surname?: string;
  gender?: string;
  day?: number;
  month?: number;
  year?: number;
  birthday?: string;
  birthplace?: string;
  birthplaceProvincia?: string;
  cf?: string;
}
