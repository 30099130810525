
export function isEmpty(obj: object) {
  return !obj || Object.keys(obj).length === 0;
}

export function isNil(value: any) {
  return !value || value === null || value === undefined || value === '';
}

export function removeFalsy(obj: any): object {
  return Object.keys(obj).filter(k => {
    if (isNil(obj[k])) {
      return false;
    }
    return !(typeof obj[k] === 'object' && isEmpty(obj[k]));
  }).reduce((acc, curr) => ({
    [curr]: obj[curr],
    ...acc,
  }), {});
}

export const removeExtraDataFactory = <F = any>(toRemove: string[]) => (data: F) => {
  toRemove.forEach(field => {
    // eslint-disable-next-line no-prototype-builtins
    if ((data as any).hasOwnProperty(field)) {
      delete (data as any)[field];
    }
  });
  return data;
};
