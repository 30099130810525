import React from 'react';
import { Box, DialogActions, Button } from '@material-ui/core';

export interface ConfirmDialogProps {
  onConfirm: () => void;
  onCancel?: () => void;
  message?: string;
  cancelText?: string;
  confirmText?: string;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = (
  {
    onConfirm,
    onCancel,
    message = 'The action is irreversible',
    cancelText = 'Cancel',
    confirmText = 'Confirm',
  },
) => (
  <Box minWidth="40vw">
    {message}
    <DialogActions>
      {onCancel && (
        <Button onClick={onCancel}>{cancelText}</Button>
      )}
      <Button onClick={onConfirm}>{confirmText}</Button>
    </DialogActions>
  </Box>
);
