
export interface LayoutState {
  title: string;
  navBar: boolean;
  background: string;
  backButton?: boolean;
  [key: string]: any;
}

export enum LayoutActions {
  MERGE_STATE = 'MERGE_STATE',
  REPLACE_STATE = 'REPLACE_STATE',
}

export interface MergeStateAction {
  type: LayoutActions.MERGE_STATE;
  state: Partial<LayoutState>;
}

export interface ReplaceStateAction {
  type: LayoutActions.REPLACE_STATE;
  state: LayoutState;
}

export type LayoutAction = MergeStateAction | ReplaceStateAction;

export const layoutReducer = (state: LayoutState, action: LayoutAction): LayoutState => {
  switch (action.type) {
    case LayoutActions.MERGE_STATE:
      return { ...state, ...action.state };
    case LayoutActions.REPLACE_STATE:
      return { ...action.state };
    default:
      throw new Error('Unknown action');
  }
};
