import React, {
  createContext, Dispatch, Reducer, useContext, useLayoutEffect, useMemo, useReducer,
} from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { LayoutAction, LayoutActions, layoutReducer, LayoutState } from './layout.reducer';

interface RouteType {
  children?: RouteType[];
  path: string;
  config: any;
  [key: string]: any;
}

export type LayoutCtx<E = Record<string, any>> = LayoutState & {
  getTitle?: (props: any) => any;
  setTitle: (title: string) => void;
  setNavBar: (bool: boolean) => void;
  setBackground: (color: string) => void;
  dispatch: Dispatch<LayoutAction>;
} & E;

const defaultLayoutState: LayoutState = {
  title: '',
  navBar: false,
  background: '#fafafa',
};

export const LayoutContext = createContext<LayoutCtx>({} as LayoutCtx);

export function useLayout<E = Record<string, any>>() {
  return useContext<LayoutCtx<E>>(LayoutContext as any);
}

const getMatchedRoute = (pathname: string, routes: RouteType[]) => {
  const getMatch = (r: RouteType[]): RouteType | null => {
    // eslint-disable-next-line no-restricted-syntax
    for (const route of r) {
      const match = matchPath(pathname, { path: route.path, exact: true });
      if (match) {
        return route;
      }
      if (route.children?.length) {
        return getMatch(route.children!);
      }
    }
    return null;
  };
  return getMatch(routes);
};

export interface LayoutProviderProps {
  routes: RouteType[];
}

export const LayoutProvider: React.FC<LayoutProviderProps> = ({ children, routes }) => {
  const [state, dispatch] = useReducer<Reducer<LayoutState, LayoutAction>>(layoutReducer, defaultLayoutState);
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    const matchedRoute = getMatchedRoute(pathname, routes);
    if (matchedRoute && matchedRoute.config) {
      dispatch({
        type: LayoutActions.REPLACE_STATE,
        state: {
          ...defaultLayoutState,
          ...matchedRoute.config,
        },
      });
    }
  }, [pathname, routes]);

  const value = useMemo<LayoutCtx>(
    () => ({
      ...state,
      dispatch,
      set: (key: string, v: any) => dispatch({ type: LayoutActions.MERGE_STATE, state: { [key]: v } }),
      setTitle: (title: string) => dispatch({ type: LayoutActions.MERGE_STATE, state: { title } }),
      setNavBar: (bool: boolean) => dispatch({ type: LayoutActions.MERGE_STATE, state: { navBar: bool } }),
      setBackground: (color: string) => dispatch({ type: LayoutActions.MERGE_STATE, state: { background: color } }),
    }), [state],
  );

  return (
    <LayoutContext.Provider value={value}>
      {children}
    </LayoutContext.Provider>
  );
};
