import { createBrowserHistory, Location } from 'history';
import { parse } from 'query-string';

function parseSearch(location: Location) {
  location.query = parse(location.search);
}

export function createHistory(options?: import('history/createBrowserHistory').BrowserHistoryBuildOptions) {
  const history = createBrowserHistory(options);

  history.listen(parseSearch);
  parseSearch(history.location);

  return history;
}
