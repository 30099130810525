
export class ErrorHandler {
  public static defaultMessage = 'Errore sconosciuto';

  public static productionMessage = 'Qualcosa è andato storto, per favore riprova';

  public message!: string;

  constructor(e: import('@apollo/client').ApolloError | string) {
    if (e) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(e);
      }
      if (process.env.REACT_APP_ENV !== 'production') {
        if (typeof e === 'string') {
          this.message = e;
        } else if (e.networkError && e.networkError) {
          this.message = e.networkError.message;
        } else if (e.graphQLErrors && e.graphQLErrors.length) {
          this.message = e.graphQLErrors[0].message;
        } else {
          this.message = e.message || ErrorHandler.defaultMessage;
        }
      } else {
        this.message = ErrorHandler.productionMessage;
      }
    } else {
      this.message = ErrorHandler.productionMessage;
    }
  }
}
