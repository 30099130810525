import React from 'react';
import { Toolbar, Grid, Typography, Divider } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  toolbar: {
    minHeight: theme.spacing(5),
  },
  infoIcon: {
    marginRight: theme.spacing(1),
  },
}));

export const EnvBar: React.FC = () => {
  const classes = useStyles();
  if (process.env.REACT_APP_ENV === 'production') {
    return (
      <></>
    );
  }
  return (
    <>
      <Toolbar variant="dense" className={classes.toolbar}>
        <Grid item container alignItems="center">
          <Info fontSize="small" color="action" className={classes.infoIcon}/>
          <Grid item sm>
            <Typography variant="caption" color="textSecondary">
              ID:
              <strong>{process.env.REACT_APP_NAME}</strong>
              - Env:
              <strong>{process.env.REACT_APP_ENV}</strong>
              - Version:
              <strong>{process.env.REACT_APP_VERSION}</strong>
              {process.env.REACT_APP_ENV === 'development' && `- Endpoint:${process.env.REACT_APP_API_ENDPOINT}`}
            </Typography>
          </Grid>
        </Grid>
      </Toolbar>
      <Divider/>
    </>
  );
};
