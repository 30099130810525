
/* eslint-disable no-console */

export function log(...args: any[]): void {
  const title = args.shift();
  console.groupCollapsed(`${new Date().toISOString()} - ${title}`);
  console.log(...args);
  console.trace();
  console.groupEnd();
}
